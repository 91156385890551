import {
    auth,
    transcriptsCollection
  } from "@/firebase";
import axios from "axios";
import CONSTANTS from '../../constants/miscellaneous.js';
import store from "@/store/index.js";
import {getFunctionUrl} from '@/functions/functionsConfig'
import getWorkspaceIdForProjectQuery from '@/utils/apiUtils.js'

const getAllSessions=()=>{
    return null;
}

/**
 * DB_READ
 * Collection: transcriptsCollection
 * Filters: docId (Document ID)
 * Type: Single fetch
 * Helper function to get a document from Firestore collection.
 * @param {string} docId - The ID of the document to retrieve.
 * @returns {Promise<Object|null>} - A promise that resolves to the document data or null if not found.
 */
export async function getTranscriptDocument(docId) {
    try {
      const docRef = transcriptsCollection.doc(docId);
      const docSnapshot = await docRef.get();
  
      if (docSnapshot.exists) {
        return docSnapshot.data();
      } else {
        console.log(`No document found with ID: ${docId}`);
        return null;
      }
    } catch (error) {
      console.error('[DB_READ_ERROR] getTranscriptDocument() Error getting document:', error);
      throw error;
    }
  }
  

const getNextPaginatedSessions=async(projectId,lastDocSnapshot)=>{

    try{
        // DB_READ
        // Collection: transcriptsCollection
        // Filters: workspace_id, userId, projectId
        // Type: Multiple fetch
        const sessionsData=await transcriptsCollection
        .where("workspace_id", "==", store.state.userWorkspace.id)
        .where("userId", "==", auth.currentUser.uid)
        .where("projectId", "==",projectId)
        .orderBy("createdOn", "desc")
        .startAfter(lastDocSnapshot)
        .limit(8)
        .get()
    
        console.log(sessionsData)
        let sessions=[];
        let lastSessionDoc=null;
        let firstSessionDoc=null;

        for(const session of await sessionsData.docs){
        // console.log(project.id,'-->',project.data().name)
            sessions.push({
            id:session.id,
            ...session.data()
            })
            lastSessionDoc=session;
        }
        firstSessionDoc=await sessionsData.docs[0];

        return { status:'success',sessions: sessions, lastSessionDoc: lastSessionDoc, firstSessionDoc:firstSessionDoc }
    }catch(error){
        console.error('[DB_READ_ERROR] getNextPaginatedSessions() Error:', error);
        return { error, status:'failure', sessions:null, lastSessionDoc:null,firstSessionDoc:null}
    }

}

const getPreviousPaginatedSessions=async(projectId,firstDocSnapshot)=>{
    try {
    // DB_READ
    // Collection: transcriptsCollection
    // Filters: workspace_id, userId, projectId
    // Type: Multiple fetch
    const sessionsData=await transcriptsCollection
                                .where("workspace_id", "==", store.state.userWorkspace.id)
                                .where("userId", "==", auth.currentUser.uid)
                                .where("projectId", "==",projectId)
                                .orderBy("createdOn", "desc")
                                .limitToLast(8)
                                .endBefore(firstDocSnapshot)
                                .get()

    let sessions=[];
    let lastSessionDoc=null;
    let firstSessionDoc=null;

    for(const session of await sessionsData.docs){
        // console.log(project.id,'-->',project.data().name)
        sessions.push({
        id:session.id,
        ...session.data()
        })
        lastSessionDoc=session;
    }
    firstSessionDoc=await sessionsData.docs[0];
    return { sessions: sessions, lastSessionDoc: lastSessionDoc, firstSessionDoc: firstSessionDoc}
    } catch (error) {
        console.error('[DB_READ_ERROR] getPreviousPaginatedSessions() Error:', error);
    }

}

const getProjectSessions=async(projectId)=>{
    try {
        // DB_READ
        // Collection: transcriptsCollection
        // Filters: workspace_id, userId, projectId
        // Type: Multiple fetch
        const sessionsData=await transcriptsCollection
                                .where("workspace_id", "==", store.state.userWorkspace.id)
                                .where("userId", "==", auth.currentUser.uid)
                                .where("projectId", "==",projectId)
                                .orderBy("createdOn", "desc")
                                .limit(8)
                                .get()

        let sessions=[];
        let lastSessionDoc=null;
        let firstSessionDoc=null;
        for(const session of await sessionsData.docs){
            // console.log(project.id,'-->',project.data().name)
            sessions.push({
                id:session.id,
                ...session.data()
            })
            lastSessionDoc=session;
        }
        firstSessionDoc=await sessionsData.docs[0];
        console.log({sessions})
        return { sessions: sessions, lastSessionDoc: lastSessionDoc, firstSessionDoc: firstSessionDoc}
    } catch (error) {
        console.error('[DB_READ_ERROR] getProjectSessions() Error:', error);
    }

}

const getAllSessionsForProject=async(projectId)=>{
    try {
        // DB_READ
        // Collection: transcriptsCollection
        // Filters: workspace_id, projectId
        // Type: Multiple fetch
        // Handle collaborators read/write
        const workspaceId = getWorkspaceIdForProjectQuery(projectId)
        console.log('[DB_READ] workspaceId:', workspaceId)
        const sessionsData=await transcriptsCollection
                                .where("workspace_id", "==", workspaceId)
                                .where("projectId", "==",projectId)
                                .orderBy("createdOn", "desc")
                                .get()
        let sessions=[];
        for(const session of await sessionsData.docs){
            // console.log(project.id,'-->',project.data().name)
            const sessionData=session.data()
            let _session = {
                id:session.id,
                projectId:sessionData.projectId,
                fileName:sessionData.fileName,
            }
            if('moveCallInProgress' in sessionData) _session = { ..._session, moveCallInProgress: sessionData.moveCallInProgress}
            sessions.push(_session)
        }
        console.log('[DB_READ] sessionsData:', sessions)
        return sessions;
    } catch (error) {
        console.error('[DB_READ_ERROR] getAllSessionsForProject() Error:', error);
    }
}

const updateSessionName=async(sessionId,newSessionName)=>{
    try{
        const sessionToUpdate=await transcriptsCollection.doc(sessionId);
        const updatedSession=await sessionToUpdate.update({fileName:newSessionName,updatedOn:new Date()})
        return { status:'success' }
    }catch(error){
        return { status:'error',error:error }
    }
   
}

const toggleSessionShareable=async(sessionId,viewState)=>{

    try{
        const sessionToUpdate=await transcriptsCollection.doc(sessionId);
        const updatedSession=await sessionToUpdate.update({
                                        shareable: viewState,
                                        updatedOn: new Date(),
                                    });
        return { status:'success' }  
    }catch(error){
        return { status:'error',error:error }
    }
    
}

const getAllSessionsForUser=async()=>{
    try{
        // DB_READ
        // Collection: transcriptsCollection
        // Filters: workspace_id, userId
        // Type: Multiple fetch
        const sessionsData=await transcriptsCollection.where("workspace_id", "==", store.state.userWorkspace.id).where("userId","==",auth.currentUser.uid)
                             .get();
        let sessions=[];
        for(const session of await sessionsData.docs){
            sessions.push({
                id:session.id,
                projectId:session.data().projectId,
                fileName:session.data().fileName,
            })
        }
        return {sessions:sessions,status:'success'};

    }catch(error){
        console.error('[DB_READ_ERROR] getAllSessionsForUser() Error:', error);
        return{ status:'error',error:error}
    }
}

const getSessionDetailsByTranscriptId=async(transcriptId)=>{
    try{
        // DB_READ
        // Collection: transcriptsCollection
        // Filters: transcriptId (document ID)
        // Type: Single fetch
        const sessionRef=await transcriptsCollection.doc(transcriptId)
        const sessionData = await sessionRef.get()
        if(sessionData.exists){
            return {
                session:{
                    id: transcriptId,
                ...sessionData.data()
                },
                status: 'success'
            }
        }else{
            console.error('session not found',transcriptId)
            return null
        }

    }catch(error){
        console.error('[DB_READ_ERROR] getSessionDetailsByTranscriptId() Error:', error);
        return{
            status:'error',error:error
        }
    }
}

const getSessionDetailsByFilename=async(fileName)=>{
    try{
        // DB_READ
        // Collection: transcriptsCollection
        // Filters: workspace_id, userId, fileName
        // Type: Multiple fetch
        const sessionsData=await transcriptsCollection
                            .where("workspace_id", "==", store.state.userWorkspace.id)
                            .where("userId", "==", auth.currentUser.uid)
                            .where("fileName", "==",fileName)
                            .get()
        let sessions=[];
        for(const session of await sessionsData.docs){
            // console.log(project.id,'-->',project.data().name)
            sessions.push({
                id:session.id,
                ...session.data()
            })
        }
        return {session:sessions[0],status:'success'};

    }catch(error){
        console.error('[DB_READ_ERROR] getSessionDetailsByFilename() Error:', error);
        return{
            status:'error',error:error
        }
    }
}

const moveNotesToAnotherSectionInASession=async(noteIds,moveToQuestionId,moveToQuestionName)=>{

    // BACKEND_API
    const requestUrl= getFunctionUrl('move_notes_sections');
    const requestParams= {
        "user_id":auth.currentUser.uid,
        "note_ids": noteIds,
        "move_to_question_id":moveToQuestionId,
        "move_to_question_name":moveToQuestionName
    }
       
    console.log({requestParams:requestParams})
    try{
        const response=await axios.post(requestUrl,requestParams);
        console.log('response',response)
        return {
            status:'success', response: response
        }
    }catch(error){
        return {
            status:'error', error:error
        }
    }
}

const getAllBotRecordedSessions=async()=>{
    try{
        // DB_READ
        // Collection: transcriptsCollection
        // Filters: workspace_id, userId
        // Type: Multiple fetch
        const sessionsData = await transcriptsCollection
                                    .where("workspace_id", "==", store.state.userWorkspace.id)
                                    .where("userId", "==", auth.currentUser.uid)
                                    .orderBy("recordingStatus")
                                    .get();
        let sessions=[];
        for(const session of await sessionsData.docs){
            // console.log(project.id,'-->',project.data().name)
            sessions.push({
                id:session.id,
                ...session.data()
            })
        }
        return {sessions:sessions,status:'success'};
        
    }catch(error){
        console.error('[DB_READ_ERROR] getAllBotRecordedSessions() Error:', error);
        console.log(error);
        return {status:'error',error:error};
    }
}


const deleteAllAINotes=async(call_id)=>{
    // const requestUrl="http://127.0.0.1:8081";
    // BACKEND_API
    const requestUrl=getFunctionUrl('manage_auto_generated_notes');
    let _requestParams = {}
    _requestParams= {
        "user_id":auth.currentUser.uid,
        "call_id": call_id,
        "action":"DELETE_ALL"
    }
    try{
        const response=await axios.post(requestUrl,_requestParams);
        // console.log('response',response)
        return {
            status:'success', response: response
        }
    }catch(error){
        return {
            status:'error', error:error
        }
    }
}

const saveModifiedTranscipt=async(_requestParams)=>{
    // const requestUrl="http://127.0.0.1:8081";
    // BACKEND_API
    const requestUrl= getFunctionUrl('update_transcript');
    try{
        const response=await axios.post(requestUrl,_requestParams);
        // console.log('response',response)
        return {
            status:'success', response: response
        }
    }catch(error){
        return {
            status:'error', error:error
        }
    }
}

const createNewTextFile=async(projectId,rawTextHTML,rawTextString,rawTextJSON,fileName)=>{
    // const requestUrl="http://192.168.1.2:8081";
    // BACKEND_API
    const requestUrl= getFunctionUrl('import_raw_text');
    const requestBody={
        'project_id': projectId,
        'raw_text_html': rawTextHTML,
        'raw_text_string': rawTextString,
        'raw_text_json': rawTextJSON,
        'user_id': auth.currentUser.uid,
    }
    if(fileName){
        requestBody['fileName'] = fileName
    }
    try{
        const response=await axios.post(requestUrl,requestBody);
        // console.log('response',response)
        return {
            status:'success', response: response
        }
    }catch(error){
        return {
            status:'error', error:error
        }
    }
}

const uploadTranscript=async(projectId,rawTranscriptHTML,rawTranscriptString,rawTranscriptJSON,fileName)=>{
    // const requestUrl="http://192.168.1.9:8080";
    // BACKEND_API
    const requestUrl= getFunctionUrl('upload_transcript');
    const requestBody={
        'project_id': projectId,
        'raw_transcript_html': rawTranscriptHTML,
        'raw_transcript_string': rawTranscriptString,
        'raw_transcript_json':rawTranscriptJSON,
        'user_id': auth.currentUser.uid,
    }
    if(fileName){
        requestBody['fileName'] = fileName
    }
    try{
        const response=await axios.post(requestUrl,requestBody);
        // console.log('response',response)
        return {
            status:'success', response: response
        }
    }catch(error){
        return {
            status:'error', error:error
        }
    }
}

const uploadCSVFile=async(projectId,file)=>{
    // Create a FormData object to hold the file
    const formData = new FormData();
    formData.append('file', file);
    formData.append('project_id', projectId); // Append the projectId
    formData.append('user_id',auth.currentUser.uid)
    // const requestUrl = "http://192.168.1.8:8083";
    // BACKEND_API
    const requestUrl= getFunctionUrl('upload_survey_csv');
    try{
        const response=await axios.post(requestUrl,formData,{
            headers:{
                'Content-Type':'multipart/form-data'
            }
        });
        console.log('response',response)
        return {
            status:'success', response: response
        }
    }catch(error){
        return {
            status:'error', error:error
        }
    }
}

const getCSVSurveyData = async(transcript_id) =>{
    // BACKEND_API
    const requestUrl = getFunctionUrl('get_survey_csv_data')
    let body ={
        "transcript_id" : transcript_id
    }
    if(auth.currentUser) body["user_id"] = auth.currentUser.uid
    try{
        const response=await axios.post(requestUrl,body);
        console.log('response',response)
        return {
            status:'success', response: response
        }
    }catch(error){
        return {
            status:'error', error:error
        }
    }
}

const processCSV = async(transcript_id,type,open_ended_columns) =>{
    // const requestUrl = "http://192.168.1.8:8084"
    // BACKEND_API
    const requestUrl = getFunctionUrl('process_survey_csv')
    let body ={
        "user_id" : auth.currentUser.uid,
        "transcript_id" : transcript_id,
        "type" : type
    }
    if(type == 'MARK_OPEN_ENDED_COLUMN') body["open_ended_columns"] = open_ended_columns
    try{
        const response=await axios.post(requestUrl,body);
        console.log('response',response)
        return {
            status:'success', response: response
        }
    }catch(error){
        return {
            status:'error', error:error
        }
    }
}

const fetchAISummaryCSVColumn=async(transcript_id,column)=>{
    // BACKEND_API
    const requestUrl = getFunctionUrl('ai_summarize_csv_column')
    let body ={
        "user_id" : auth.currentUser.uid,
        "transcript_id" : transcript_id,
        "column" : column
    }
    try{
        const response=await axios.post(requestUrl,body);
        console.log('response',response)
        return {
            status:'success', response: response
        }
    }catch(error){
        return {
            status:'error', error:error
        }
    }
}

const translateTranscript=async(transcriptId)=>{
    // const requestUrl="http://127.0.0.1:8081";
    // BACKEND_API
    const requestUrl= getFunctionUrl('translate_transcript');
    const requestBody={
        'user_id': auth.currentUser.uid,
        'transcript_id': transcriptId,
    }
    try{
        const response=await axios.post(requestUrl,requestBody);
        console.log('response',response)
        return {
            status:'success', response: response
        }
    }catch(error){
        return {
            status:'error', error:error
        }
    }
}

export default {
    getProjectSessions,
    updateSessionName,
    toggleSessionShareable,
    getNextPaginatedSessions,
    getPreviousPaginatedSessions,
    getSessionDetailsByFilename,
    getSessionDetailsByTranscriptId,
    getAllSessionsForUser,
    getAllBotRecordedSessions,
    getAllSessionsForProject,
    moveNotesToAnotherSectionInASession,
    deleteAllAINotes,
    saveModifiedTranscipt,
    createNewTextFile,
    uploadTranscript,
    uploadCSVFile,
    getCSVSurveyData,
    processCSV,
    fetchAISummaryCSVColumn,
    translateTranscript
}