// functionsConfig.js
/**
 * Instructions for Using the Configuration File
 *
 * 1. Set Environment:
 *    - Production: Before pushing any changes to the production environment, ensure that the environment variable is set to `'prod'`.
 *    - Local Testing: For local testing, set the environment variable to `'staging'`:
 *
 * 2. Connecting to Local or Production Instances:
 *    - By default, functions will connect to the production environment. To connect a function to a local instance, update the configuration
 *      in the `staging` section by setting the `local` flag to `true` and specifying the desired port.
 *    - Example Configurations:
 * 
 *      - Use Localhost:
 *
 *        "functionName": { "path": "/functionName", "port": 3004, "local": true }
 *
 *      - Use Production:
 *
 *        "functionName": { "path": "/functionName", "port": 3004, "local": false }
 *
 *
 * 3. Default Behavior:
 *    - If the `local` flag is not set, functions will default to connecting to the production environment.
 */

const environment = 'prod'; 
// const environment = 'staging'; 

const config = {
  "prod": {
    "baseUrl": "https://us-central1-poc-project-309214.cloudfunctions.net",
    "functions": {
      "project_access_control" : "/project_access_control",
      "get_subscription_details_v2": "/get_subscription_details_v2",
      "trigger_transactional_emails": "/trigger_transactional_emails",
      "get_transcription_duration": "/get_transcription_duration",
      "notes-analysis": "/notes-analysis",
      "get_tags_analysis": "/get_tags_analysis",
      "get_taggednotes_analysis": "/get_taggednotes_analysis",
      "get_question_view_tags_notes_count": "/get_question_view_tags_notes_count",
      "analysis_invite_collaborator": "/analysis_invite_collaborator",
      "get_project_callnames": "/get_project_callnames",
      "autotagging_project_handler": "/autotagging_project_handler",
      "validate_password": "/validate_password",
      "manage_metadata": "/manage_metadata",
      "get_shared_projects": "/get_shared_projects",
      "get_recent_projects": "/get_recent_projects",
      "move_session_projects": "/move_session_projects",
      "delete_project": "/delete_project",
      "get_session_count": "/get_session_count",
      "teams_invite_collaborator": "/teams_invite_collaborator",
      "get_scripts_and_questions": "/get_scripts_and_questions",
      "manage_question_script": "/manage_question_script",
      "recall_calendar": "/recall_calendar",
      "get_question_notes_count": "/get_question_notes_count",
      "delete_question": "/delete_question",
      "get_guide_suggestions": "/get_guide_suggestions",
      "get_search_results": "/get_search_results",
      "ai_summarize_results": "/ai_summarize_results",
      "move_notes_sections": "/move_notes_sections",
      "manage_auto_generated_notes": "/manage_auto_generated_notes",
      "update_transcript": "/update_transcript",
      "import_raw_text": "/import_raw_text",
      "upload_transcript": "/upload_transcript",
      "upload_survey_csv": "/upload_survey_csv",
      "get_survey_csv_data": "/get_survey_csv_data",
      "process_survey_csv": "/process_survey_csv",
      "ai_summarize_csv_column": "/ai_summarize_csv_column",
      "translate_transcript": "/translate_transcript",
      "create_clip": "/create_clip",
      "manage_project_tags": "/manage_project_tags",
      "get_nudges_counts": "/get_nudges_counts",
      "set_session_expired_user": "/set_session_expired_user",
      "workspace": "/workspace",
      "invite_bot": "/invite_bot",
      "get_calendar_token": "/get_calendar_token",
      "manage_analysis_user_access": "/manage_analysis_user_access",
      "add_signup_email_zapier_webhook": "/add_signup_email_zapier_webhook",
      "link_ph_user": "/link_ph_user",
      "get_sso_org_info": "/get_sso_org_info",
      "download_zoom_recording": "/download_zoom_recording",
      "notes_analysis_project_export": "/notes_analysis_project_export",
      "process_video": "/process_video",
      "fetch_zoom_recordings_list": "/fetch_zoom_recordings_list",
      "generate_transcription": "/generate_transcription",
      "connect_zoom": "/connect_zoom",
      "ph_users": "/ph_users",
      "ph_create_transcript_document": "/ph_create_transcript_document",
      "ph_process_video": "/ph_process_video",
      "get_ph_sample_call": "/get_ph_sample_call"        
    }
  },
  "staging": {
    "baseUrl": "http://localhost",
    "functions": {
      "get_subscription_details_v2": { "path": "/get_subscription_details_v2", "port": 3001, "local": false },
      "trigger_transactional_emails": { "path": "/trigger_transactional_emails", "port": 3002, "local": false },
      "get_transcription_duration": { "path": "/get_transcription_duration", "port": 3003, "local": false },
      "notes-analysis": { "path": "/notes-analysis", "port": 3004, "local": false },
      "get_tags_analysis": { "path": "/get_tags_analysis", "port": 3005, "local": false },
      "get_taggednotes_analysis": { "path": "/get_taggednotes_analysis", "port": 3006, "local": false },
      "get_question_view_tags_notes_count": { "path": "/get_question_view_tags_notes_count", "port": 3007, "local": false },
      "analysis_invite_collaborator": { "path": "/analysis_invite_collaborator", "port": 3008, "local": false },
      "get_project_callnames": { "path": "/get_project_callnames", "port": 3009, "local": false },
      "autotagging_project_handler": { "path": "/autotagging_project_handler", "port": 3010, "local": false },
      "validate_password": { "path": "/validate_password", "port": 3011, "local": false },
      "manage_metadata": { "path": "/manage_metadata", "port": 3012, "local": false },
      "get_shared_projects": { "path": "/get_shared_projects", "port": 3013, "local": false },
      "get_recent_projects": { "path": "/get_recent_projects", "port": 8082, "local": false },
      "move_session_projects": { "path": "/move_session_projects", "port": 3015, "local": false },
      "delete_project": { "path": "/delete_project", "port": 3016, "local": false },
      "get_session_count": { "path": "/get_session_count", "port": 3017, "local": false },
      "teams_invite_collaborator": { "path": "/teams_invite_collaborator", "port": 3018, "local": false },
      "get_scripts_and_questions": { "path": "/get_scripts_and_questions", "port": 3019, "local": false },
      "manage_question_script": { "path": "/manage_question_script", "port": 3020, "local": false },
      "recall_calendar": { "path": "/recall_calendar", "port": 3021, "local": false },
      "get_question_notes_count": { "path": "/get_question_notes_count", "port": 3022, "local": false },
      "delete_question": { "path": "/delete_question", "port": 3023, "local": false },
      "get_guide_suggestions": { "path": "/get_guide_suggestions", "port": 3024, "local": false },
      "get_search_results": { "path": "/get_search_results", "port": 3025, "local": false },
      "ai_summarize_results": { "path": "/ai_summarize_results", "port": 3026, "local": false },
      "move_notes_sections": { "path": "/move_notes_sections", "port": 3027, "local": false },
      "manage_auto_generated_notes": { "path": "/manage_auto_generated_notes", "port": 3028, "local": false },
      "update_transcript": { "path": "/update_transcript", "port": 3029, "local": false },
      "import_raw_text": { "path": "/import_raw_text", "port": 3030, "local": false },
      "upload_transcript": { "path": "/upload_transcript", "port": 3031, "local": false },
      "upload_survey_csv": { "path": "/upload_survey_csv", "port": 3032, "local": false },
      "get_survey_csv_data": { "path": "/get_survey_csv_data", "port": 3033, "local": false },
      "process_survey_csv": { "path": "/process_survey_csv", "port": 3034, "local": false },
      "ai_summarize_csv_column": { "path": "/ai_summarize_csv_column", "port": 3035, "local": false },
      "translate_transcript": { "path": "/translate_transcript", "port": 3036, "local": false },
      "create_clip": { "path": "/create_clip", "port": 3037, "local": false },
      "manage_project_tags": { "path": "/manage_project_tags", "port": 3038, "local": false },
      "get_nudges_counts": { "path": "/get_nudges_counts", "port": 3039, "local": false },
      "set_session_expired_user": { "path": "/set_session_expired_user", "port": 3040, "local": false },
      "workspace": { "path": "/workspace", "port": 8081, "local": false },
      "invite_bot": { "path": "/invite_bot", "port": 3042, "local": false },
      "get_calendar_token": { "path": "/get_calendar_token", "port": 3043, "local": false },
      "manage_analysis_user_access": { "path": "/manage_analysis_user_access", "port": 3044, "local": false },
      "add_signup_email_zapier_webhook": { "path": "/add_signup_email_zapier_webhook", "port": 3045, "local": false },
      "link_ph_user": { "path": "/link_ph_user", "port": 3046, "local": false },
      "get_sso_org_info": { "path": "/get_sso_org_info", "port": 3047, "local": false },
      "download_zoom_recording": { "path": "/download_zoom_recording", "port": 3048, "local": false },
      "notes_analysis_project_export": { "path": "/notes_analysis_project_export", "port": 3049, "local": false },
      "process_video": { "path": "/process_video", "port": 3050, "local": false },
      "fetch_zoom_recordings_list": { "path": "/fetch_zoom_recordings_list", "port": 3051, "local": false },
      "generate_transcription": { "path": "/generate_transcription", "port": 3052, "local": false },
      "ph_users": { "path": "/ph_users", "port": 3053, "local": false },
      "ph_create_transcript_document": { "path": "/ph_create_transcript_document", "port": 3054, "local": false },
      "ph_process_video": { "path": "/ph_process_video", "port": 3055, "local": false },
      "get_ph_sample_call": { "path": "/get_ph_sample_call", "port": 3056, "local": false },
      "connect_zoom": { "path": "/connect_zoom", "port": 3057, "local": false },
    }
  }
};

const getFunctionUrl = (functionName) => {
  const envConfig = config[environment];
  // console.log("get", functionName)
  if (environment === 'prod') {
    return `${envConfig.baseUrl}${envConfig.functions[functionName]}`;
  } else {
    const functionConfig = envConfig.functions[functionName];
    if (functionConfig.local) {
      return `${envConfig.baseUrl}:${functionConfig.port}${functionConfig.path}`;
    } else {
      return `${config.prod.baseUrl}${config.prod.functions[functionName]}`;
    }
  }
};

module.exports = {
  getFunctionUrl,
};